import React, { createContext, useCallback, useContext, useState } from 'react';

import { consolidationService } from '../services';
import { GroupCompany } from '../services/consolidation/types';

import { useAuth } from './auth';

interface ConsolidationContextData {
  groupCompanies?: GroupCompany[];
  fetchGroupCompanies: () => Promise<GroupCompany[]>;
  inviteGroupCompany: (company: {
    email: string;
    taxId: string;
  }) => Promise<GroupCompany>;
  hasInvite: () => Promise<boolean>;
  acceptInvite: (consolidationCompanyId: string) => Promise<GroupCompany>;
  shareDataset: (datasetId: string, companyId: string) => Promise<void>;
  revokeDataset: (datasetId: string, companyId: string) => Promise<void>;
  listDatasetShares: (datasetId: string) => Promise<GroupCompany[]>;
  clearState: () => void;
}

const ConsolidationContext = createContext<ConsolidationContextData>(
  {} as ConsolidationContextData
);

export const ConsolidationProvider: React.FC = ({ children }) => {
  const [groupCompanies, setGroupCompanies] = useState<GroupCompany[]>();
  const { user } = useAuth();

  const fetchGroupCompanies = useCallback(() => {
    return new Promise<GroupCompany[]>((resolve, reject) => {
      consolidationService.ListGroupCompanies().then((result) => {
        if (result.error) reject(result);
        else {
          setGroupCompanies(result);
          resolve(result);
        }
      });
    });
  }, []);

  const inviteGroupCompany = useCallback((user) => {
    return new Promise<GroupCompany>((resolve, reject) => {
      consolidationService.InviteGroupCompany(user).then((result) => {
        if (result?.error) reject(result.error);
        else {
          setGroupCompanies((state) => (state ? [...state, result] : [result]));
          resolve(result);
        }
      });
    });
  }, []);

  const hasInvite = useCallback(async () => {
    if (!user || user.company.isConsolidationCompany) {
      return false;
    }
    let group;
    if (groupCompanies === undefined) {
      group = await fetchGroupCompanies();
    } else {
      group = groupCompanies;
    }
    for (let i = 0; i < group.length; i += 1) {
      if (group[i].active === false) {
        return true;
      }
    }
    return false;
  }, [fetchGroupCompanies, groupCompanies, user]);

  const acceptInvite = useCallback((consolidationCompanyId: string) => {
    return new Promise<GroupCompany>((resolve, reject) => {
      consolidationService
        .AcceptInvite(consolidationCompanyId)
        .then((result) => {
          if (result.error) reject(result);
          else {
            setGroupCompanies((state) =>
              state?.map((c) => {
                if (c.id === consolidationCompanyId) return result;
                return c;
              })
            );
            resolve(result);
          }
        });
    });
  }, []);

  const shareDataset = useCallback((datasetId: string, companyId: string) => {
    return new Promise<void>((resolve, reject) => {
      consolidationService.ShareDataset(datasetId, companyId).then((result) => {
        if (result.error) reject(result.error);
        else resolve();
      });
    });
  }, []);

  const revokeDataset = useCallback((datasetId: string, companyId: string) => {
    return new Promise<void>((resolve, reject) => {
      consolidationService
        .RevokeDataset(datasetId, companyId)
        .then((result) => {
          if (result.error) reject(result.error);
          else resolve();
        });
    });
  }, []);

  const listDatasetShares = useCallback((datasetId: string) => {
    return new Promise<GroupCompany[]>((resolve, reject) => {
      consolidationService.ListDatasetShares(datasetId).then((result) => {
        if (result.error) reject(result.error);
        else resolve(result);
      });
    });
  }, []);

  const clearState = useCallback(() => {
    setGroupCompanies(undefined);
  }, []);

  return (
    <ConsolidationContext.Provider
      value={{
        groupCompanies,
        fetchGroupCompanies,
        inviteGroupCompany,
        hasInvite,
        acceptInvite,
        shareDataset,
        revokeDataset,
        listDatasetShares,
        clearState,
      }}
    >
      {children}
    </ConsolidationContext.Provider>
  );
};

export function useConsolidation(): ConsolidationContextData {
  const context = useContext(ConsolidationContext);
  if (!context) {
    throw new Error(
      'useConsolidation must be used within a ConsolidationProvider'
    );
  }
  return context;
}
