import Api from '../api';

import { GroupCompany, InviteGroupCompanyParams } from './types';

class ConsolidationService extends Api {
  async InviteGroupCompany(data: InviteGroupCompanyParams) {
    return this.doRequest<GroupCompany>({
      endpoint: '/consolidation/invite',
      data,
    });
  }

  async ListGroupCompanies() {
    return this.doRequest<GroupCompany[]>({
      endpoint: '/consolidation/group',
    });
  }

  async AcceptInvite(consolidationCompanyId: string) {
    return this.doRequest<GroupCompany>({
      endpoint: '/consolidation/accept',
      data: { consolidationCompanyId },
      method: 'PUT',
    });
  }

  async ShareDataset(datasetId: string, consolidationCompanyId: string) {
    return this.doRequest<void>({
      endpoint: '/consolidation/share-dataset',
      data: {
        datasetId,
        consolidationCompanyId,
      },
      method: 'POST',
    });
  }

  async RevokeDataset(datasetId: string, consolidationCompanyId: string) {
    return this.doRequest<void>({
      endpoint: '/consolidation/revoke-dataset-share',
      data: {
        datasetId,
        consolidationCompanyId,
      },
      method: 'POST',
    });
  }

  async ListDatasetShares(datasetId: string) {
    return this.doRequest<GroupCompany[]>({
      endpoint: `/consolidation/dataset/${datasetId}/shares`,
    });
  }
}

export const consolidationService = new ConsolidationService();
